.mainContainer {
	position: relative;
	height: 100vh;
	width: 100%;

	.form {
		position: absolute;
		top: 41vh;
		left: 4.5vw;
		width: 91vw;
		height: fit-content;
		border-radius: 3.1rem;
		background: white;
		box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.25);
		padding: 2rem 2rem;
		display: flex;
		flex-direction: column;
		gap: 2.2vh;
		padding-bottom: 4.5rem;

		.header {
			display: flex;
			justify-content: space-between;
			position: relative;
			margin-bottom: 10px;
			.textContainer {
				padding-left: 10px;
				padding-top: 2.5rem;

				.heading {
					font-weight: 600;
					font-size: 3.3rem;
					line-height: 5rem;
					color: #000;
					margin-bottom: 10px;
				}

				.text {
					font-size: 2rem;
					color: gray;
				}
			}
		}

		.buttons {
			padding: 2.5rem 2.5rem;
			display: grid;
			grid-template-columns: 0.75fr 0.75fr;
			grid-column-gap: 2rem;
			justify-content: center;

			.signInButton {
				background-color: white;
				color: black;
				width: 100%;
				padding: 2rem 0;
				border-radius: 60px;
				border: none;
			}

			.activeButton {
				background: var(--gradient1);
				color: white;
				box-shadow: 0px 0px 2rem 3px rgba(0, 0, 0, 0.25);
			}

			.submitButton {
				width: 67vw;
				border-radius: 12px;
				padding: 2.5rem 5.5rem;
			}
		}

		.inputContainer {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			justify-content: center;
			overflow-y: auto;
			max-height: calc(100% - 10rem);

			&::-webkit-scrollbar {
				display: none;
			}

			.lableContainer {
				.label {
					margin-left: 10px;
					font-weight: 400;
					font-size: 3rem;
				}
			}

			.input {
				display: flex;
				position: relative;
				gap: 2.5rem;
				justify-content: center;
				.label {
					margin-left: 10px;
					font-weight: 400;
					font-size: 2.4rem;
				}
				.inputBox {
					padding: 1rem;
					border-radius: 10px;
					border: none;
					width: 8.3rem;
					height: 7.6rem;
					background-color: #eeeeee;
					font-size: 5rem;
					text-align: center;
				}

				input[type="number"]::-webkit-inner-spin-button,
				input[type="number"]::-webkit-outer-spin-button {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					margin: 0;
				}
			}
		}

		.submitButtonContainer {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;

			.signInButton {
				background-color: white;
				color: black;
				width: 100%;
				padding: 2rem 0;
				border-radius: 60px;
				border: none;
				box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.25);
			}

			.activeButton {
				background: var(--gradient1);
				color: white;
			}

			.submitButton {
				width: 100%;
				border-radius: 12px;
				padding: 2.5rem 5.5rem;
			}
		}

		.design {
			display: flex;
			justify-content: center;
			align-items: center;
			.time {
				display: flex;
				font-size: 1.8rem;
				line-height: 2.7rem;
				.timer {
					color: #1731ba;
					font-size: 1.8rem;
					line-height: 2.7rem;
					margin-bottom: 0;
				}
				.text2 {
					cursor: pointer;
					background: transparent;
					border: none;
					margin-right: 0.5rem;
					font-size: 1.8rem;
				}
				.text2:disabled {
					cursor: default;
				}
			}
		}
	}
}

@media screen and (max-height: 668px){
	.mainContainer{
		.form{
			top: 35vh
		}
	}
}
