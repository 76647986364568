.overlay {
    background-color: white;
    padding: 8.7rem 31.2rem 10rem 30.4rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    width: 121rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay h2 {
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 6.3rem;
    color: #444444;
    margin-bottom: 3.5rem;
}

.overlay p {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.3rem;
    color: #999999;
    margin-bottom: 2.3rem;
}

.overlay label {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.3rem;
    color: #444444;
    margin-bottom: 3.5rem;
}

.overlay input {
    width: 100%;
    width: 58.6rem;
    padding: 1.7rem 0 1.5rem 1.9rem;
    border-radius: 1rem;
    background: #F2F2F2;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #000;
    border: none;
    margin-bottom: 4.2rem;
    outline: none;
}

.overlay input[type="date"] {
    padding-right: 2rem;
    cursor: pointer;
    user-select: none;
}

.overlay .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overlay .actions .btn {
    font-size: 1.4rem;
    line-height: 2.1rem;
    width: 36.7rem;
    background-color: #3A0DBB;
    color: #ffffff;
    border: none;
    margin-bottom: 4rem;
    padding: 1.9rem 3rem;
    cursor: pointer;
    filter: drop-shadow(0px, 4.07px, 20.35px rgba(0, 0, 0, 0.25));
    transition: all .2s ease-in-out;
    border-radius: 1rem;
}

.overlay .actions .btn:hover {
    background-color: #1c0068;
}

.overlay .actions .btn:disabled {
    background-color: #444444;
    cursor: default;
}

.overlay .actions .link {
    background: none;
    width: 36.7rem;
    border: none;
    color: #444444;
    cursor: pointer;
    border-radius: 1rem;
    padding: 1.9rem 3rem;
    transition: all .2s ease-in-out;
}

.overlay .actions .link:hover {
    background-color: #97979792;
}

@media screen and (max-width : 431px) {
    .overlay {
        padding: 7rem 3rem;
        width: 100%;
        bottom: -50vh;
        top: auto;
        left: auto;
        transform: translateY(-50vh);
        transition: all 1s ease-in-out;
        border-radius: 13px 13px 0px 0px;
    }

    .overlay h2 {
        margin-bottom: 1.5rem;
    }

    .overlay p {
        margin-bottom: 5.3rem;
    }

    .overlay label {
        font-weight: 600;
        font-size: 3rem;

    }

    .overlay input {
        width: 100%;
        padding: 2.5rem 2rem;
        margin-bottom: 2.2rem;
        font-size: 2.5rem;
    }

    .overlay .actions .btn {
        font-size: 2rem;
        width: 100%;
        padding: 3rem 3rem;
        box-shadow: 0px 0px 2rem 3px rgba(0, 0, 0, 0.25);
    }

    .overlay .actions .link {
        opacity: .7;
    }
}