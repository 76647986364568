.landing-bg {
    width: 100%;
    height: 100vh;
    background: var(--gradient3);
    padding: 2rem 2rem;
}

.landing-bg .logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 88vh - 4rem);
    /* background-color: red; */
}

.landing-bg .logo-container .small-logo {
    position: static;
    width: 20vw;
    height: 100%;
}