 .bg {
     width: 100%;
     height: 100vh;
     background: var(--gradient4);
     overflow: hidden;
     position: relative;
 }

 .bg .white {
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     right: 0;
 }

 .bg .white img {
     position: absolute;
     top: 0;
     right: 0;
     border: 10px solid #00f;
     transform: scale(1.5) rotate(0deg);
 }

 .bg .logo {
     position: relative;
     top: 10%;
     left: 6%;
     width: 33rem;
     height: 35rem;
 }

 .bg .logo img {
     opacity: 70%;
     width: 100%;
     height: 100%
 }

 @media screen and (max-width: 431px) {
     .bg .white img {
         transform: translate(751px, 210px) rotate(349deg) scale(1.5);
     }

     .bg .logo {
         position: relative;
         top: 4%;
         left: 16%;
         width: 49rem;
         height: 49rem;
     }
 }

 @media screen and (max-height: 668px) {
     .bg .white img {
         transform: translate(628px, 210px) rotate(349deg) scale(1.5);
     }

     .bg .logo {
         position: relative;
         top: 3%;
         left: 20%;
         width: 37rem;
         height: 37rem;
     }
 }

 @media screen and (max-height: 741px) {
     .bg .white img {
         transform: translate(597px, 210px) rotate(353deg) scale(1.5);
     }

     .bg .logo {
         position: relative;
         top: 3%;
         left: 22%;
         width: 37rem;
         height: 37rem;
     }
 }

 @media screen and (max-width: 344px) {
     .bg .white img {
         transform: translate(751px, 210px) rotate(349deg) scale(1.5);
     }

     .bg .logo {
         position: relative;
         top: 4%;
         left: 11%;
         width: 47rem;
         height: 47rem;
     }
 }