.Container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Container::-webkit-scrollbar {
    display: none;
}

.Explore {
    display: flex;
    flex-direction: column;
}

.ExploreContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0 6.6rem 0 7.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9.8rem;
}



.ExploreLeft {
    width: 75.3rem;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 10rem;
}

.ExploreLeft::-webkit-scrollbar {
    width: 0%;
    display: none;
}

.ExploreLeft h1 {
    width: 32.4rem;
    height: 11.6rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    line-height: 138.4%;
    color: #000000;
    margin-bottom: 5.3rem;
    text-align: left;
}

.ExploreLeft .ExploreYourWorldContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    align-items: center;
}

.ExploreLeft .SearchContainer {
    height: 6rem;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.ExploreLeft .SearchContainer input {
    height: 100%;
    width: 0rem;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
    padding: 0;
    font-size: 3rem;
    transition: all 0.3s linear;
    margin-right: 0rem;
    /* transform: translateX(30rem); */
}

.ExploreLeft .SearchContainer input.active {
    width: 25rem;
    padding: 0 1rem;
    margin-right: 1rem;
    transform: translateX(0rem);
}

.ExploreLeft .SearchContainer .btn .SearchIcon {
    width: 100%;
    height: 100%;
}

.SearchContainer button {
    border: none;
    background: transparent;
    cursor: pointer;

}

button.btn {
    width: 4rem;
    height: 4rem;
    padding: 5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}

button.btn .redDot {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #f00;
    position: absolute;
    top: 0rem;
    right: 0rem;
    color: #fff;
    font-size: 100%;
}

button.btn:hover {
    background: #ccd0ff;
    transition: all 0.2s ease-in;

}

button.btn:hover svg path {
    transition: all 0.1s ease-in;
    fill: #2992e3;
}

.recommendedContainer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 138.4%;
    color: #000000;
    text-shadow: 0rem .4rem .4rem rgba(0, 0, 0, 0.25);
    margin-bottom: 1.8rem;
}

.recommendedDiv {
    width: 100%;
    height: 28.4rem;
    display: flex;
    align-items: center;
    position: relative;
}

/* FIXME: Start */
.HorizontalScroll {
    position: absolute;
    top: 0px;
    left: -20.4rem;
    width: 28.4rem;
    height: 342%;
    overflow-y: auto;
    overflow-x: hidden;
    transform: rotate(-90deg) translateY(-80px);
    transform-origin: right top;
    padding: 0 !important;
}

.HorizontalScroll img {
    display: block;
    margin: 0;
    width: 24.69rem;
    height: 28.4rem;
    margin-left: 1.7rem;
    margin-bottom: -1.5rem;
    transform: rotate(90deg);
}

.HorizontalScroll img:first-child {
    margin-top: -1.8rem;
}

.HorizontalScroll img:last-child {
    margin-bottom: 6rem;
}

.HorizontalScroll::-webkit-scrollbar {
    height: .5rem;
    width: .5rem;
    display: none;
}

/* FIXME: End */


/* ExploreMindCont1 Start */
.ExploreMindCont1 {
    width: 70rem;
    height: 59.1rem;
    border-radius: 2.9rem;
    margin: 0 auto;
    margin-bottom: 2.9rem;
    overflow: hidden;
    position: relative;
}

.ExploreMindCont1 img {
    width: 100%;
    height: 100%;
}

.ExploreMindCont1 .ExploreMindCont1Overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
    top: 0;
    width: 100%;
    height: 100%;
}

.ExploreMindCont1 .MindTop {
    position: absolute;
    top: 3.1rem;
    left: 2.9rem;
    right: 19.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ExploreMindCont1 .MindTop div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.ExploreMindCont1 .MindTop div h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.89277rem;
    line-height: 123.4%;
    text-align: center;
    color: #FFFFFF;
}

.ExploreMindCont1 .MindTop div span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.20449rem;
    line-height: 123.4%;
}

.ExploreMindCont1 .MindTop div img {
    width: 4.982px;
    height: 4.982px;
    border-radius: 50%;
}

.ExploreMindCont1 .MindTop h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.21773rem;
    line-height: 123.4%;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    user-select: none;
}

.ExploreMindCont1 .MindBottom {
    height: 4.909rem;
    width: 100%;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2.5px);
    border-radius: .982205rem .982205rem 2.19333rem 1.98444rem;
    padding: 0 1rem 0rem 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ExploreMindCont1 .MindBottom .commentText {
    width: 22.8rem;
    height: 4.374rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.04159rem;
    line-height: 123.4%;
    color: #FFFFFF;
    padding-top: 1.1rem;
}

.actionContainer {
    width: 20rem;
    height: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.actionContainer div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 6rem;
    cursor: pointer;
}

.actionContainer div:active img {
    transition: all .3s ease-in-out;
    transform: scale(1.5);
}


.actionContainer div p {
    width: 2.4rem;
    height: 2.4rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.89955rem;
    line-height: 123.4%;
    color: #FFFFFF;
    margin-left: .5rem;
}

.actionContainer img {
    width: 100%;
    height: 100%;
}

/* ExploreMindCont1 End */




/* ExploreImageMainContainer Start*/
.ExploreImageMainContainer {
    margin-top: 2.2rem;
}

.ExploreImageMainContainer h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 138.4%;
    color: #000000;
    margin-bottom: 1.6rem;
}

.ExploreImageContainer {
    display: grid;
    grid-template-columns: repeat(3, 28.6rem);
    grid-template-rows: repeat(5, 17.6rem);
    column-gap: 1.5rem;
    row-gap: 1rem;
}

.ExploreImageContainer .row2 {
    grid-row: 1/3;
}

.ExploreImageContainer .row21 {
    grid-row: 3/5;
    grid-column: 3;
}

.ExploreImageContainer img {
    width: 100%;
    height: 100%;
}

/* ExploreImageMainContainer End*/




.ExploreRight {
    min-width: 54.3rem;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    padding-top: 2rem;
}

.InputContainer {
    width: 42.35rem;
    border-bottom: .25rem solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5rem;
    padding-bottom: 1.5rem;
}

.InputContainer input {
    border: none;
    outline: none;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 138.4%;
    color: #3A3A3A;
    background: transparent;
}

.InputContainer img {
    width: 3.2rem;
    height: 3.2rem;
}

.TagContainer {
    margin-top: 4rem;
}

.TagContainer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 138.4%;
    color: #000000;
    margin-bottom: 3rem;
    padding-left: 1rem;
}

.TagContainer .btnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1rem;
}

.btnContainer button {
    padding: 1rem 3.5rem;
    height: 4.852rem;
    min-width: 16.555rem;
    border: 0.0951424rem solid #DBDBDB;
    border-radius: 4.75712rem;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.09313rem;
    line-height: 138.4%;
    color: #686868;
}

.btnContainer button:hover {
    transition: all .5s ease-in-out;
    background: #3A0DBB;
    color: #fff;
}

.btnContainer button:active {
    transition: all .09s ease;
    background: #15014e;
    color: #fff;
}

.ScrollCont {
    overflow-y: scroll;
    padding-bottom: 10rem;
    margin-top: 3rem;
    scrollbar-width: none;
}

.ScrollCont::-webkit-scrollbar {
    display: none;
}

.AddContainer {
    width: 54.3rem;
    height: 23.9rem;
    border-radius: 3rem;
    overflow: hidden;
}

.AddContainer img {
    width: 100%;
    height: 100%;
}

.LikeContainer {
    margin-top: 2.9rem;

}

.LikeContainer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 138.4%;
    color: #000000;
    margin-bottom: 3rem;
}

.LikeImages {
    display: grid;
    grid-template-columns: 23.8rem 23.8rem;
    grid-template-rows: 17.6rem 17.6rem;
    grid-row-gap: 1rem;
    grid-column-gap: 2rem;
}

.LikeImages img {
    width: 100%;
    height: 100%;
}


/* for L amd M mobile screens */
@media screen and (max-width : 431px) {
    .ExploreContainer {
        padding: 2rem 2.6rem 0 2.6rem;
    }

    .ExploreImageMainContainer {
        margin-top: 2.2rem;
        margin-bottom: 2.2rem;
    }

    .ExploreImageContainer {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(5, 1fr);
    }

    .ExploreMindCont1 {
        width: 100%;
    }

    .actionContainer {
        width: 25rem;
    }

    .recommendedContainer p {
        width: 100%;
    }

    .recommendedDiv {
        width: 100%;
        overflow-x: scroll;
        margin-bottom: 4rem;
    }

    .HorizontalScroll {
        overflow-x: scroll;
    }

    .HorizontalScroll::-webkit-scrollbar {
        display: none;
    }

    .ExploreLeft h1 {
        width: 25.4rem;
        height: 11.6rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 4.2rem;
        line-height: 127.4%;
        margin-bottom: 1.3rem;
    }

    .TagContainer {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .btnContainer button:hover {
        background: rgb(240 240 240);
        color: #686868;
    }
}

/* for S mobile screens */
@media screen and (max-width : 321px) {
    .ExploreLeft .ExploreYourWorldContainer h1 {

        width: 21.4rem;
        height: 9.6rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 3.5rem;
        /* line-height: 127.4%;  */
        margin-bottom: 1.3rem;
    }

    .ExploreLeft .SearchContainer input.active {

        width: 20rem;
        padding: 0 1rem;
        margin-right: 1rem;
        transform: translateX(0rem);
    }
}