.container {
	/* position: relative; */
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	padding: 4rem 5rem 5rem 5rem;

	&::-webkit-scrollbar {
		display: none;
	}

	.header {
		height: 6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 10rem;

		p {
			font-weight: 600;
			font-size: 4.2rem;
			line-height: 5.8rem;
			color: #000;
		}

		button {
			background: transparent;
			border: none;
			cursor: pointer;
			padding: 1rem;
		}
	}

	.main-content {
		margin: 3rem 0;
		display: grid;
		grid-row-gap: 5.4rem;

		.info {
			display: grid;
			grid-row-gap: 3rem;

			.heading {
				font-weight: 600;
				font-size: 3rem;
				line-height: 3rem;
				color: #000;
			}

			.subheading{
				font-weight: 600;
				font-size: 1.5rem;
				line-height: 3rem;
				color: #000;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				column-gap: 0.9rem;
				row-gap: 1.8rem;
			}

			.deactive {
				background-color: #ff3232;

				p {
					color: #ffffff;
				}
			}
		}
	}
}

@media screen and (max-width: 432px) {
	.container {
		padding: 3rem 7rem 5rem 7rem;

		.header {
			padding-right: 0;
			height: fit-content;

			.LeftContainerTop {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 2rem;

				.SettingText {
					font-family: "Poppins";
					font-style: normal;
					font-weight: 600;
					font-size: 4.4rem;
					line-height: 6.1rem;
					color: #000000;
					margin-top: 4rem;
					margin-bottom: 3.7rem;
				}

				img {
					border-radius: 5px;
					padding: 1rem 2rem;
					background-color: #3a0dbb;
				}
			}
		}

		.main-content{
			.info{
				.heading{
					margin-bottom: 2rem;
				}
			}
		}
	}
}
