.main-container {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.container {
    width: 85rem;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-0%, -50%);
    padding: 4rem 14.1rem 5rem 10.7rem;
    background: white;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
}

.container .heading {
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 6.3rem;
    margin-bottom: .5rem;
}

.container .text1 {
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 3.3rem;
}

.container .logo-section {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .logo-section .logo-container {
    width: 11.5rem;
    height: 11.5rem;
    border-radius: 50%;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
}

.container .logo-section .logo-container .logo {
    width: 100%;
    height: 100%;
}

.container .input {
    display: grid;
    grid-row-gap: 1rem;
}

.container .input .input-field {
    display: flex;
    flex-direction: column;
}

.container .input .text2 {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2.1rem;
    color: #444444;
    margin-bottom: .9rem;
}

.container .input .input-box {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #909090;
    border-radius: 10px;
    padding: 1.9rem;
}

.container .actions {
    margin: 3rem 0 0 0;
    display: grid;
    grid-row-gap: 3rem;
}

.choseTem {
    font: inherit;
    background: #F2F2F2;
    border: 1px solid #CCCCCC;
    border-radius: 1rem;
    color: #CCCCCC;
    padding: 1.9rem 3rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.choseTem:hover,
.choseTem:active {
    background: #3A0DBB;
    border: 1px solid #3A0DBB;
}

.skipNow {
    font: inherit;
    background: #F2F2F2;
    border: 1px solid #CCCCCC;
    border-radius: 1rem;
    color: #CCCCCC;
    padding: 1.9rem 3rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    transition: all .3s ease-in-out;
}

.activeBtn {
    background: #3A0DBB;
    border: 1px solid #3A0DBB;
}

/* .skipNow:hover,
.skipNow:active {
    background: #3A0DBB;
    border: 1px solid #3A0DBB;
} */

.skipNow:disabled {
    background-color: #5a5e6a;
    cursor: default;
}

.skip {
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #909090;
}

@media screen and (max-width: 768px) {
    .container {
        width: 60rem;
        position: absolute;
        top: 60%;
        right: 50%;
        transform: translate(50%, -50%);
        padding: 5rem 3rem;
        background: white;
        box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
        border-radius: 3.1rem;
    }

    .container .heading {
        font-size: 4.2rem;
        line-height: normal;
    }

    .container .text1 {
        font-size: 1.3rem;
        line-height: 2.1rem;
    }

    .container .input .text2 {
        font-weight: 300;
        font-size: 2.3rem;
        line-height: 3.3rem;
        color: #444444;
        margin-left: 5px;
    }

    .container .input .input-box {
        color: black;
        background: #F2F2F2;
        border: none;
        outline: none;
        border-radius: 1rem;
        padding: 3rem 2rem;
        border-radius: 12px;
        font-size: 2rem;
    }

    .container .actions button {
        background: #3A0DBB;
        width: 100%;
        border-radius: 12px;
        padding: 3rem 5.5rem;
        box-shadow: 0px 0px 2rem 3px rgba(0, 0, 0, 0.25);
        font-size: 2rem;
    }

    .container .input {
        grid-row-gap: 2rem;
    }

    .container .logo-section .logo-container {
        width: 18rem;
        height: 18rem;
        color: #909090;
        flex-direction: row;
        gap: 3px;
        font-size: 2rem;
        font-weight: normal;
        line-height: normal;
    }

    .container .actions {
        margin-top: 4rem;
    }
}

.bg {
    width: 100%;
    height: 100vh;
    background: var(--gradient4);
    overflow: hidden;
    position: relative;
}

.bg .white {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.bg .white img {
    position: absolute;
    top: 0;
    right: 0;
    border: 10px solid #00f;
    transform: scale(1.5) rotate(0deg);
}

.bg .logo {
    position: relative;
    top: 10%;
    left: 6%;
    width: 33rem;
    height: 35rem;
}

.bg .logo img {
    opacity: 70%;
    width: 100%;
    height: 100%
}

@media screen and (max-width: 431px) {
    .bg .white img {
        transform: translate(751px, 210px) rotate(349deg) scale(1.5);
    }

    .bg .logo {
        position: relative;
        top: 4%;
        left: 24%;
        width: 38rem;
        height: 38rem;
    }
}

@media screen and (max-width: 391px) {

    .bg .logo {
        position: relative;
        top: 4%;
        left: 26%;
        width: 33rem;
        height: 33rem;
    }
}

@media screen and (max-height: 668px) {
    .bg .white img {
        transform: translate(628px, 210px) rotate(349deg) scale(1.5);
    }

    .bg .logo {
        position: relative;
        top: 3%;
        left: 20%;
        width: 37rem;
        height: 37rem;
    }

    .container {
        padding: 3rem 3rem;
    }

    .container .heading {
        font-size: 3.2rem;
        line-height: normal;
    }

    .container .actions {
        margin-top: 3rem;
    }
}

@media screen and (max-height: 741px) {
    .bg .white img {
        transform: translate(597px, 210px) rotate(353deg) scale(1.5);
    }

    .bg .logo {
        position: relative;
        top: 3%;
        left: 31%;
        width: 22rem;
        height: 22rem;
    }
}

@media screen and (max-width: 344px) {
    .bg .white img {
        transform: translate(751px, 210px) rotate(349deg) scale(1.5);
    }

    .bg .logo {
        position: relative;
        top: 4%;
        left: 11%;
        width: 47rem;
        height: 47rem;
    }
}

@media screen and (max-width: 344px) {
    .container {
        width: 56rem;
    }

    .bg .logo {
        position: relative;
        top: 4%;
        left: 19%;
        width: 37rem;
        height: 37rem;
    }
}