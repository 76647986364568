/* Container Common Start*/
.Container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}
/* Container Common end*/

/* Home start */
.Home {
	display: flex;
	flex-direction: column;
}

.homeContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	// overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

/* Slider start */
.Home .homeContainer .sliderContainer {
	width: 100%;
	height: 15rem;
	margin-top: 2rem;
	display: flex;
	align-items: center;
}

.Home .sliderContainer .customSlick {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.Home .sliderContainer .slide-wrapper {
	width: 20rem;
	padding: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		user-select: none;
	}
}

.cl {
	margin-left: 3rem;
	margin-right: 3rem;
}

.Home .sliderContainer .slider-item {
	position: relative;
	width: 9rem !important;
	height: 8.7rem !important;
	z-index: 3;
	cursor: pointer;

	.Plus {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		background: linear-gradient(46deg, #0f0aa4 23.68%, #2992e3 99.39%);
		position: absolute;
		bottom: -1rem;
		right: -1rem;
		color: #fff;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 3rem;
	}
}

.Home .sliderContainer .slider-item:before {
	content: "";
	width: 105%;
	height: 108%;
	background: #35e4ce;
	position: absolute;
	top: -4px;
	left: -3px;
	border-radius: 2rem;
	transform: rotate(42.97deg);
}

.Home .sliderContainer .slider-item .image {
	overflow: hidden;
	width: 9rem;
	height: 8.7rem;
	background: #c4c4c4;
	border: 1.27594px solid #ffffff;
	border-radius: 2rem;
	transform: rotate(42.97deg);

	img {
		width: 100%;
		height: 100%;
		transform: rotate(-42.97deg) scale(1.8);
	}
}

.Home .sliderContainer .slider-item .title {
	height: 1.4rem;
	width: 9rem;
	width: 6rem;
	font-weight: 500;
	font-size: 0.91rem;
	line-height: 1.4rem;
	color: #12b8a5;
	display: block;
	transform: rotate(-45deg);
	position: relative;
	left: 4.6rem;
	top: -2.3rem;
	left: 5.6rem;
	top: -1.3rem;
	// border: 1px solid #000;
	overflow: hidden;
}

/* Slider end */

.homeContainer .imagesMainContainer {
	margin-top: 3rem;
	width: 100%;
	height: 100%;
	/* height: calc(612px + 150px + 45px); */
	// overflow-y: scroll;
	position: sticky;
	top: 10rem;
	left: 0;
	padding-left: 3rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 0.7rem;
	// border: 5px solid #f00;
}

/* Left start */
.homeContainer .imagesMainContainer .homeLeftContainer {
	// border: 5px solid #ff0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scroll-behavior: smooth;
	// scroll-snap-type: y proximity;

	&::-webkit-scrollbar {
		display: none;
	}
}

/* PostImageContainer Component start */
.homeLeftContainer .imageContainer {
	// scroll-snap-align: start;
	// scroll-snap-stop: always;
	width: 100%;
	min-height: 35rem;
	max-height: 70rem;
	margin-bottom: 1.7rem;
	background: #000000;
	position: relative;
	border-radius: 2rem;
	box-shadow: -1px 3px 5px 2px rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border: 0.2rem solid #35e4ce;

	.MediaImage {
		object-fit: contain;
		max-width: 100%;
		min-height: 20rem;
		max-height: 55rem;
	}

	.PollContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(4px);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		border-radius: 2rem;

		.PollSection {
			width: 50%;
			height: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 2rem;

			p {
				color: #fff;
				text-align: center;
				font-family: "Poppins";
				font-size: 2.4rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			.ButtonCont {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 1rem;

				button {
					cursor: pointer;
					min-width: 10rem;
					height: 5rem;
					padding: 0 1rem;
					border: 1.287px solid #fff;
					background: #fff;
					color: #000;
					font-family: "Poppins";
					font-size: 1.8rem;
					font-style: normal;
					font-weight: 800;
					line-height: normal;

					&:last-child {
						background: transparent;
						color: #fff;
					}
				}
			}

			.ResultCont {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				gap: 1rem;

				.Result {
					width: 100%;

					.Top {
						padding: 0 3rem;
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 1.1rem;

						span {
							color: #fff;
							font-family: "Poppins";
							font-size: 1.8rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}

					.Bottom {
						width: 100%;
						height: 0.9rem;
						background: rgba(217, 217, 217, 0.22);

						span {
							display: block;
							width: 0%;
							height: 100%;
							background: #4c65a8;
							transition: all 0.3s ease-in-out;
						}
					}
				}
			}
		}
	}

	.Popup {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(4, 4, 4, 0.66);
		backdrop-filter: blur(4px);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;

		.btnContainer {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			button {
				width: 30rem;
				border-radius: 0.5rem;
				border: 1.287px solid #fff;
				color: #fff;
				background: transparent;
				font-size: 2rem;
				font-family: "Poppins";
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 2rem;
				transition: all 0.2s ease-in-out;
				cursor: pointer;

				&:hover {
					color: #000;
					background: #fff;
				}
			}
		}
	}
}

.imageContainer .postHeader {
	width: 80%;
	height: 5rem;
	position: absolute;
	top: 3.4rem;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 6rem;
	padding: 2rem;
	border-radius: 5rem;

	.leftPostHeader {
		img {
			cursor: pointer;
		}
	}
}

.postHeader .rightPostHeader {
	display: flex;
	align-items: center;
	cursor: pointer;

	.imagePostBox {
		width: 3.7rem;
		height: 3.7rem;
		border: 1px solid #ffffff;
		border-radius: 50%;
		margin-right: 0.8rem;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

.postHeader .rightPostHeader .textPostBox h3 {
	// width: 9.1rem;
	height: 1.76rem;
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 1.7rem;
	color: #ffffff;
}

.postHeader .rightPostHeader .textPostBox p {
	// width: 7.58rem;
	height: 1.11rem;
	font-weight: 400;
	font-size: 0.89rem;
	line-height: 1.1rem;
	color: #ffffff;
}

.imageContainer .postFooter {
	width: 100%;
	height: 5rem;
	position: absolute;
	bottom: 2.6rem;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	gap: 2.9rem;

	.commentContainer {
		width: 61rem;
		height: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: linear-gradient(0deg, rgba(35, 35, 35, 0.2), rgba(35, 35, 35, 0.2)), rgba(50, 50, 50, 0.1);
		backdrop-filter: blur(2.49586px);
		border-radius: 0.98rem 0.98rem 2.18rem 1.98rem;
		// margin-right: 2.9rem;
		padding-left: 3.4rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;

		.comment {
			// width: 22.7rem;
			flex: 1;
			height: 100%;
			font-weight: 400;
			font-size: 1.03rem;
			line-height: 1.1rem;
			color: #ffffff;
			display: block;
			display: flex;
			align-items: center;
			overflow: hidden;
		}

		.actionContainer {
			// width: 30rem;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 1rem;
			padding-right: 3.4rem;

			& div {
				height: 100%;
				display: flex;
				align-items: center;
				// justify-content: space-between;
				gap: 0.4rem;
				cursor: pointer;
				overflow: hidden;

				&.h {
					img {
						width: 5rem;
						height: 5rem;
					}
				}

				&.c {
					img {
						width: 3rem;
						height: 3rem;
					}
				}

				&.s {
					width: 5rem;
				}

				p {
					text-align: center;
					height: 100%;
					width: 3rem;
					font-weight: 600;
					font-size: 1.89rem;
					color: #ffffff;
					margin: 0;
					padding-top: 0.3rem;
				}

				img {
					width: 100%;
					height: 100%;
				}

				&:active img {
					transition: all 0.3s ease-in-out;
					transform: scale(1.2);
				}
			}
		}
	}

	.multi {
		display: flex;
		width: 8rem;
		height: 100%;
		position: absolute;
		top: 0;
		right: 2%;
		color: #fff;
		font-weight: 500;
		font-size: 1.39rem;
		line-height: 2.1rem;
		padding-bottom: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 2.2rem;
			height: 2.2rem;
			position: relative;
		}

		.img2 {
			top: 1rem;
			left: -1rem;
		}
	}
}

/* PostImageContainer Component end */

/* Left end */

/* right start */
.homeContainer .imagesMainContainer .homeRightContainer {
	width: 61rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	/* Message box */
	.messageBox {
		width: 100%;
		min-height: 15rem;
		max-height: 15.97rem;
		background: #262626;
		box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.15);
		border-radius: 1rem;
		overflow: hidden;
		position: relative;
		margin-bottom: 1rem;

		.searchSection2 {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 4rem 4rem 5rem 4.5rem;

			form {
				width: 100%;
				height: 4.4rem;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;

				input {
					width: 100%;
					height: 100%;
					background: rgba(219, 219, 219, 0.49);
					border-radius: 0.69rem;
					color: #ffffff;
					font-weight: 500;
					font-size: 1.39rem;
					line-height: 1.2rem;
					padding: 2.2rem;
					border: none;

					&::placeholder {
						color: #ffffff;
						font-weight: 500;
						font-size: 1.39rem;
						line-height: 1.1rem;
					}

					&:focus-visible {
						border: none;
						outline-width: 0;
					}
				}

				button {
					width: 8rem;
					height: 100%;
					background: #4a4a4a;
					border-radius: 0.69rem;
					border: none;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					padding-top: 0.3rem;

					&:hover {
						background: #5b2ae1;
					}
					&:active {
						background: #4400ff;
					}
				}
			}

			ul {
				align-self: flex-start;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-top: 1.8rem;

				li {
					list-style: none;
					margin-left: 3.4rem;
					font-weight: 400;
					font-size: 1.397rem;
					line-height: 2.1rem;
					text-align: center;
					color: #ffffff;
					text-decoration: none;
					padding: 0.2rem 0.6rem;
					cursor: pointer;
				}
			}
		}

		.ProgressBar {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 1rem;
			width: 100%;
			overflow: hidden;

			span {
				height: 100%;
				width: 0%;
				display: block;
				background: #1aff00;
			}
		}
	}

	/* Images */
	.staticImageContainer {
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-bottom: 1rem;

		.ImageWrapper {
			width: 100%;
			height: 100%;
			overflow: hidden;
			display: flex;
			gap: 2rem;
			border-radius: 1rem;

			img {
				width: 100%;
				height: 100%;
			}

			.ImageInner {
				overflow: hidden;
				flex-basis: 50%;
				border-radius: 1rem;
			}
		}
	}
}
/* right end */

/* Home end */

.loaderContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.postContainer {
	width: 100%;
	height: 7rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.NoMorePostText {
	text-align: center;
	font-size: 2rem;
}

@media screen and (max-width: 431px) {
	.Home .homeContainer .sliderContainer {
		height: 14rem;
		margin-top: 0;
		border-bottom: 1px solid white;
	}
	.homeContainer .imagesMainContainer {
		margin-top: 2rem;
		position: sticky;
		top: 10rem;
		left: 0;
		padding-left: 0;
		padding: 2rem;
	}

	.imageContainer .postHeader {
		top: 2rem;
		left: 3rem;
		margin: 0;
		padding: 2rem;
	}

	.imageContainer .postFooter {
		height: 7rem;
		bottom: 0rem;

		// .commentContainer {
		// 	width: 61rem;
		// 	height: 100%;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 50%;
		// 	transform: translateX(-50%);
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: space-between;
		// 	background: linear-gradient(0deg, rgba(35, 35, 35, 0.2), rgba(35, 35, 35, 0.2)), rgba(50, 50, 50, 0.1);
		// 	backdrop-filter: blur(2.49586px);
		// 	border-radius: 0.98rem 0.98rem 2.18rem 1.98rem;
		// 	// margin-right: 2.9rem;
		// 	padding-left: 3.4rem;
		// 	padding-top: 0.5rem;
		// 	padding-bottom: 0.5rem;
		// }
	}
}
