.main-container {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.container {
    width: 88rem;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-0%, -50%);
    padding: 10rem 15.8rem 7.9rem 12.2rem;
    background: white;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
}

.container .heading {
    font-weight: 600;
    font-size: 6.2rem;
    line-height: 9.3rem;
}

.container .text1 {
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 3.3rem;
}

.container .text-container {
    display: grid;
    grid-row-gap: 2rem;
    padding: 1rem 0;
    margin: 5.4rem 0 7.8rem 0;
}

.container .text-container .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container .text-container .row2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container .text-container .row3 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.container .text-container p {
    font-weight: 500;
    font-size: 1.79rem;
    line-height: 2.7rem;
    color: #444444;
}

.container .btn {
    width: 60rem;
    background-color: #3A0DBB;
    font: inherit;
    border: 1px solid #CCCCCC;
    border-radius: 1rem;
    color: #CCCCCC;
    padding: 1.9rem 3rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.container .btn:disabled {
    background-color: #5a5e6a;
    cursor: default;
}

@media screen and (max-width: 427px) {
    .container {
        width: 60rem;
        position: absolute;
        top: 60%;
        right: 50%;
        transform: translate(50%, -50%);
        padding: 5rem 3rem;
        background: white;
        box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
        border-radius: 3.1rem;
        text-align: center;
    }

    .container .heading {
        font-size: 4rem;
        line-height: 3rem;
        margin-bottom: 2rem;
    }

    .container .text1 {
        font-size: 1.3rem;
        line-height: 3rem;
        margin-top: 1rem;
    }

    .container .btn {
        width: 100%;
        background-color: #3A0DBB;
        font: inherit;
        border: 1px solid #CCCCCC;
        border-radius: 12px;
        color: #CCCCCC;
        padding: 2.5rem 3rem;
        cursor: pointer;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.4rem;
    }

    .container .text-container {
        padding: 1rem 3rem;
    }

    .container .text-container .row1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .container .text-container .row2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }

    .container .text-container .row3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .container .text-container p {
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.7rem;
        color: #444444;
        font-weight: 600;
    }

}