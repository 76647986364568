.overlay {
    background-color: white;
    padding: 8.3rem 13.9rem 5rem 17rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    width: 121rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay .form .text h2 {
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 6.3rem;
    color: #444444;
}

.overlay .form .text p {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.3rem;
    color: #999999;
    margin-bottom: 4.1rem;
}

.overlay .form .address-section {
    display: flex;
    gap: 1.8rem;
    margin-bottom: 3.4rem;
}

.overlay .form .address-section .address {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.overlay .form label {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: #444444;
}

.overlay .form .address-section input {
    padding: 1.7rem 1.9rem;
    border-radius: 10px;
    background: #F2F2F2;
    font-weight: 500;
    font-size: 1.16rem;
    line-height: 1.8rem;
    color: #000;
    border: none;
    overflow: hidden;
}

.overlay .form .other-info {
    display: flex;
    gap: .8rem;
}

.overlay .form .other-info .info {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.overlay .form .other-info .info:nth-child(1) {
    width: 15rem;
}

.overlay .form .other-info .info:nth-child(2) {
    width: 21.4rem;
}

.overlay .form .other-info .info:nth-child(3) {
    width: 20.2rem;
}

.overlay .form .other-info .info:nth-child(4) {
    width: 14.7rem;
}

.overlay .form .other-info .info input {
    padding: 1.7rem 1.9rem;
    border-radius: 10px;
    background: #F2F2F2;
    font-weight: 500;
    font-size: 1.16rem;
    line-height: 1.8rem;
    color: #000;
    border: none;
    overflow: hidden;
    margin: .5rem 0;
}

.overlay .form .actions {
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    /* margin-bottom: 6rem; */
}

.overlay .form .actions .btn {
    font-size: 1.4rem;
    line-height: 2.1rem;
    width: 36.7rem;
    background-color: #3A0DBB;
    border: none;
    color: #ffffff;
    font-weight: 500;
    filter: drop-shadow(0px, 4.07px, 20.35px rgba(0, 0, 0, 0.25));
    margin-bottom: 1rem;
    padding: 1.9rem 3rem;
    cursor: pointer;
    border-radius: 1rem;
    transition: all .2s ease-in-out;
}

.overlay .form .actions .btn:hover {
    background-color: #1c0068;
}

.overlay .form .actions .btn:disabled {
    background-color: #444444;
    cursor: default;
}

.overlay .form .actions .link {
    width: 36.7rem;
    background: none;
    border: none;
    color: #444444;
    cursor: pointer;
    border-radius: 1rem;
    padding: 1.9rem 3rem;
    transition: all .2s ease-in-out;
}

.overlay .form .actions .link:hover {
    background-color: #97979792;
}

@media screen and (max-width: 431px) {
    .overlay {
        padding: 3rem 3rem;
        width: 100%;
        bottom: -50vh;
        top: auto;
        left: auto;
        transform: translateY(-50vh);
        transition: all 1s ease-in-out;
        border-radius: 13px 13px 0px 0px;
    }

    .overlay .form .address-section {
        flex-direction: column;
    }

    .overlay .form .address-section input {
        padding: 2.5rem 2rem;
        border-radius: 12px;
        font-size: 2rem;
    }

    .overlay .form label {
        font-weight: 600;
        font-size: 2rem;
    }

    .overlay .form .other-info {
        display: flex;
        flex-direction: column;
    }

    .overlay .form .other-info .doubleInfoContainer1 {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 2rem;
    }

    .overlay .form .other-info .doubleInfoContainer2 {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 2rem;
    }

    .overlay .form .other-info .info:nth-child(1) {
        width: 100%;
    }

    .overlay .form .other-info .info:nth-child(2) {
        width: 100%;
    }

    .overlay .form .other-info .info:nth-child(3) {
        width: 100%;
    }

    .overlay .form .other-info .info:nth-child(4) {
        width: 100%;
    }

    .overlay .form .other-info .info input {
        font-size: 1.5rem;
        padding: 2.5rem 2rem;
    }

    .overlay .form .actions {
        margin-top: 4rem;
    }

    .overlay .form .actions .btn {
        font-size: 2rem;
        width: 100%;
        padding: 3rem 3rem;
        margin-bottom: 5rem;
        box-shadow: 0px 0px 2rem 3px rgba(0, 0, 0, 0.25);
    }

    .overlay .form .actions .link {
        width: 100%;
        opacity: .7;
    }
}