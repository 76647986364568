.landing-bg {
    width: 100%;
    height: 100vh;
    background: var(--gradient3);
    padding: 3rem 11rem;
}

.landing-bg .logo-container {
    position: relative;
}

.landing-bg .logo-container .big-logo {
    height: 90vh;
    /* width: 60vw; */
    /* border: 10px solid #000; */
}

.landing-bg .logo-container .small-logo {
    position: absolute;
    top: 35%;
    left: 10%;
    width: 20rem;
}

@media screen and (max-width: 431px) {

    .landing-bg {
        width: 100%;
        height: 100vh;
        background: var(--gradient3);
        padding: 2rem 2rem;
    }

    .landing-bg .logo-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        /* height: calc(100vh - 515.2px); */
        height: calc(100vh - 566.2px);
        /* background-color: red; */
    }

    .landing-bg .logo-container .small-logo {
        position: static;
        width: 65vw;
    }
}

@media screen and (max-height: 668px) {
    .landing-bg .logo-container .small-logo {
        position: static;
        width: 43vw;
    }
}

@media screen and (max-height: 741px) {
    .landing-bg .logo-container {
        height: calc(100vh - 514.2px);
    }
}

@media screen and (max-height: 858px) {
    .landing-bg .logo-container {
        height: calc(100vh - 525.2px);
    }
}

@media screen and (max-width: 376px) {

    .landing-bg .logo-container .small-logo {
        width: 43vw;
    }
}

@media screen and (max-width: 360px) {
    .landing-bg .logo-container .small-logo {
        width: 52vw;
    }
}

@media screen and (max-height: 668px) {
    .landing-bg .logo-container {
        height: calc(100vh - 471.2px);
    }
}

/* @media screen and (max-width: 391px) {

    .landing-bg .logo-container {
        height: calc(100vh - 531.2px);
    }
} */