.Container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Container::-webkit-scrollbar {
    display: none;
}

.Setting {
    display: flex;
    gap: 10.7rem;
    justify-content: center;
}

.LeftSettingContainer {
    position: relative;
    height: 110vh;
    /* width: 50.6rem; */
    flex-basis: 100%;
    font-size: 1rem;
    padding-left: 18rem;
}

.SettingText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 4.4rem;
    line-height: 6.1rem;
    color: #000000;
    margin-top: 4rem;
    margin-bottom: 3.7rem;
}

.AccountText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.9rem;
    line-height: 4.1rem;
    color: #000000;
    margin-bottom: 1.5rem;
}

.AccountDiv {
    width: 100%;
    height: 37.5rem;
    background: #F5F5F5;
    border-radius: 2.64rem;
    display: flex;
    flex-direction: column;
    /* FIXME: */
    padding: 1.74rem 3.5rem 1.5rem 2.64rem;
}

.AccountDivTop {
    display: flex;
    gap: 3.9rem;
    align-items: center;
    justify-content: center;
}

.AccountDivTop .ProfileImageContainer {
    height: 15.2rem;
    width: 15.2rem;
    overflow: hidden;
    position: relative;
}

.AccountDivTop .ProfileImageContainer input {
    display: none;
}

.AccountDivTop .ProfileImageContainer .SelectImageBtn {
    width: 3.5rem;
    height: 3.5rem;
    background: #FFFFFF;
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
    border-radius: 50%;
    padding: .9rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.AccountDivTop .ProfileImageContainer .SelectImageBtn img {
    width: 100%;
    height: 100%;
}

.AccountDivTop .ProfileImageContainer .ProfileImg {
    height: 15.2rem;
    width: 15.2rem;
    border-radius: 50%;
    overflow: hidden;
}

.AccountDivTop .ProfileImageContainer .ProfileImg img {
    min-height: 15.2rem;
    width: 15.2rem;
}

.AccountDivTop .NameContainer {
    height: 15.2rem;
    flex-basis: calc(100% - 15.2rem - 3.9rem);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AccountDivTop .NameContainer h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 3.3rem;
    line-height: 4.7rem;
    color: #000000;
}

.AccountDivTop .NameContainer p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #959595;
}

.AccountDivBottom {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .TopSectionDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .3rem;

        h1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 1.48rem;
            line-height: 2rem;
            color: #959595;
        }

        button {
            background: #3A0DBB;
            border: none;
            cursor: pointer;
            color: #fff;
            padding: .5rem 1rem;
            font-size: 1.48rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: .5rem;
            transition: all .3s ease-in-out;

            &:hover {
                background: #53389f;
            }
        }

    }
}

.AccountDivBottom textarea {
    flex: 1;
    /* width: 100%; */
    /* height: 7.5rem; */
    background: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.26rem;
    line-height: 1.8rem;
    color: #323232;
    scrollbar-width: none;
    overflow-y: scroll;
    white-space: pre-wrap;
    border: none;
    outline: none;
    resize: none;
    /* border: 1px solid #000; */

    &::-webkit-scrollbar {
        width: .4rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: #3A0DBB;
    }
}

.AccountDivBottom .AccountDivBottomBottom {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    /* padding-right: 5rem; */
}

/* .AccountDivBottom .AccountDivBottomBottom button:first-child {
    width: 17rem;
    height: 4.67rem;
    background: transparent;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.26rem;
    line-height: 1.5rem;
    color: #000;
} */

.AccountDivBottom .AccountDivBottomBottom button {
    /* width: 17rem; */
    height: 4.67rem;
    /* background: #3A0DBB; */
    background: #d2d2d3;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.26rem;
    line-height: 1.5rem;
    /* color: #FFFFFF; */
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    padding: 0 3rem;
    border-radius: .5rem;

    &:hover {
        background: #3A0DBB;
        color: #FFFFFF;

    }
}

.ActiveContainer {
    margin-top: 3.27rem;
}

.ActiveText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.96rem;
    line-height: 4.1rem;
    color: #000000;
}

.ActiveTextP {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #000000;
    margin-top: 1.29rem;
}

.SwitchContainer {
    margin-top: 1.68rem;
    display: flex;
    align-items: center;
}

.SwitchText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.6rem;
    color: #323232;
    margin-left: 1.9rem;
}

.LogOutBtn {
    width: 100%;
    height: 9rem;
    border: none;
    background: #232323;
    border-radius: 1.05rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 6.2rem;
    transition: all .3s ease-in-out;

    &:hover {
        background: #000000;
    }
}

.AddAccountText {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #323232;
    text-align: center;
    margin-top: 6.1rem;
    padding-bottom: 6.3rem;
    text-decoration: none;
}

.RightSettingContainer {
    /* width: 50.4rem; */
    flex-basis: 100%;
    min-height: 110vh;
    position: relative;
    font-size: 1rem;
    padding-right: 22rem;
}

.PreferenceText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.96rem;
    line-height: 4.1rem;
    color: #000000;
    margin-bottom: 2.1rem;
    margin-top: 13rem;
}

.PreferenceContainer {
    margin-bottom: 1.68rem;
    width: 100%;
    height: 9.62rem;
    background: #FFFFFF;
    border-radius: 1.05rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5.1rem 0 3.3rem;
    cursor: pointer;
    transition: all .3s ease-in-out;
}


.PreferenceContainer h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #000000;
}

.PreferenceContainer p {
    display: block;
    height: 3.6rem;
    width: 22.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.26rem;
    line-height: 1.8rem;
    color: #959595;
}

.PreferenceContainer:hover {
    background: #3A0DBB;
}

.PreferenceContainer:hover h3 {
    color: #fff;
}

.PreferenceContainer:hover p {
    color: #EEEEEE;
}

.PreferenceContainer:hover path {
    fill: #fff;
}

.PreferenceContainerSwitch {
    margin-bottom: 1.68rem;
    width: 100%;
    height: 9.62rem;
    background: #FFFFFF;
    border-radius: 1.05rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5.1rem 0 3.3rem;
}

.PreferenceContainerSwitch h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #000000;
}

.h31 {
    width: 13.8rem;
}

.h32 {
    width: 23.7rem;
}

.h33 {
    width: 19.9rem;
}

.BlockContainer {
    padding-left: 1rem;
    margin-top: 2.75rem;
}

.BlockContainer h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.96rem;
    line-height: 4.1rem;
    color: #000000;
}

.BlockContainer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #403F3F;
    margin-top: 1.5rem;
}

.BlockContainer a {
    display: block;
    margin-top: 3.3rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #3A0DBB;
    text-decoration: none;
}

@media screen and (max-width : 500px) {
    .Container {
        position: relative;
        width: 100%;
    }

    .Setting {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        /* margin-top: 10rem; */
        padding: 3rem 5rem;
    }

    .LeftSettingContainer {
        position: relative;
        height: fit-content;
        width: 100%;
        flex-basis: 100%;
        font-size: 1rem;
        padding-left: 0rem;
    }

    .RightSettingContainer {
        /* width: 50.4rem; */
        flex-basis: 100%;
        min-height: 0vh;
        position: relative;
        font-size: 1rem;
        padding: 2rem 0rem;
    }

    .PreferenceText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2.96rem;
        line-height: 4.1rem;
        color: #000000;
        margin-bottom: 2.1rem;
        margin-top: 0;
    }

    .AccountDivBottom .AccountDivBottomBottom {
        margin-top: 0rem;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1em;
    }

    .AccountDivBottom .AccountDivBottomBottom:nth-child(1) {
        background: white;
    }

    .AccountDivBottom .AccountDivBottomBottom:nth-child(2) {
        background: #3A0DBB;
        color: #FFFFFF;
    }

    .AccountDivBottom .AccountDivBottomBottom button {
        /* width: 17rem; */
        height: 6rem;
        /* background: #3A0DBB; */
        background: #F5F5F5;
        font-family: 'Roboto';
        font-size: 1.5rem;
        line-height: 1rem;
        padding: 0 4rem;
        border-radius: 0rem;
    }

    .AccountDivBottom .AccountDivBottomBottom .activeButton2 {
        background: #3A0DBB;
        color: #FFFFFF;
    }

    .AccountDiv {
        height: 40rem;
        padding: 1.5rem 3rem 3rem 3rem;
    }

    .AccountDivBottom {
        gap: 1rem;
    }

    .AccountDivBottom textarea {
        height: 5rem;
        font-size: 1.26rem;
        line-height: 1.8rem;
        padding: 1rem;

        &::-webkit-scrollbar {
            width: .4rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 1rem;
            background: #3A0DBB;
        }
    }

    .AccountDivBottom .TopSectionDiv {
        padding: 0 1rem;
    }

    .AccountDivTop .ProfileImageContainer .SelectImageBtn {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .LeftContainerTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
    }

    .LeftContainerTop img {
        border-radius: 5px;
        padding: 1rem 2rem;
        background-color: #3A0DBB;
    }

    .PreferenceContainer p {
        height: fit-content;
    }

    .AccountText {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 320px) {
    .AccountDivBottom .AccountDivBottomBottom button {
        /* width: 17rem; */
        height: 5rem;
        /* background: #3A0DBB; */
        background: #F5F5F5;
        font-family: 'Roboto';
        font-size: normal;
        line-height: normal;
        padding: 0 2rem;
        border-radius: 0rem;
        font-weight: normal;
    }

    .AccountDivBottom .AccountDivBottomBottom {
        margin-top: 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
    }

    .AccountDiv {
        height: 40rem;
        padding: 2rem 2.6rem 2rem 2.6rem;
    }

    .AccountDivTop .NameContainer h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 344px) {
    .AccountDivBottom .AccountDivBottomBottom button {
        padding: 0 3rem;
    }
}