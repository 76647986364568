.outerDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
}

.overlay {
    background-color: white;
    padding: 6rem 12rem 9rem 9.9rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    width: 70%;
    height: 80%;
    /* width: 121rem;
        height: 91.2rem; */
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    overflow-y: scroll;
    scrollbar-width: none;
    border-radius: 1rem;
}

.overlay::-webkit-scrollbar {
    width: 0.5rem;
}

.overlay::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    background: #020eff;
}

.overlay h2 {
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 6.3rem;
    color: #444444;
    margin-bottom: 2.5rem;
}

.overlay article {
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 3.3rem;
    color: #999999;
    text-align: justify;

    p {
        font-weight: 400;
        font-size: 1.7rem;
        line-height: 3.3rem;
        color: #999999;
        text-align: justify;
        margin-bottom: 1rem;
    }

    .li {
        font-weight: 600;
        font-size: 2.2rem;
        line-height: 3.3rem;
        color: #999999;
        text-align: justify;
        margin-bottom: 1rem;
        text-transform: capitalize;
    }

    .h6 {
        font-weight: 600;
        font-size: 2.2rem;
        line-height: 3.3rem;
        color: #999999;
        text-align: justify;
        margin-bottom: 2rem;
        margin-top: 5rem;
        text-transform: capitalize;
        text-align: center;
    }
}

.padding {
    padding-left: 5rem;
}


@media screen and (max-width : 431px) {
    .overlay {
        height: 100vh;
        width: 100vw;
        background-color: #E7E9FE;
        padding: 3rem 3.9rem 0 4.5rem;
        top: auto;
        left: auto;
        transform: translate(0%, 0%);
    }

    .overlay .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5rem;
    }

    .overlay .header img:nth-child(2) {

        border-radius: 5px;
        padding: 1rem 2rem;
        background-color: #3a0dbb;

    }
}