.Container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.Container::-webkit-scrollbar {
	display: none;
}

.BlockedUser {
	display: flex;
	gap: 6.9rem;
	justify-content: center;
}

.LeftBlockedUserContainer {
	position: relative;
	height: 100%;
	flex-basis: 100%;
	font-size: 1rem;
	overflow-y: scroll;
	scrollbar-width: none;
	padding: 0 1rem 3rem 14rem;
}

.LeftBlockedUserContainer::-webkit-scrollbar {
	display: none;
}

.BlockedUserText {
	position: sticky;
	top: 0;
	width: 100%;
	background: #e7e9fe;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 4.2rem;
	line-height: 5.8rem;
	color: #000000;
	margin-bottom: 1.2rem;
	padding-top: 3rem;
}

.BlockedUserDetail {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 1.8rem;
	line-height: 2.5rem;
	color: #000000;
	margin-bottom: 3.7rem;
}

.BlockUserBox {
	width: 98%;
	height: 9.7rem;
	background: #f5f5f5;
	margin-bottom: 1.9rem;
	box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 2.7rem 0 1.4rem;
}

.BlockBoxLeft {
	display: flex;
	align-items: center;
	gap: 2.5rem;
}

.BlockBoxLeft img {
	width: 7.2rem;
	height: 7.2rem;
	border-radius: 50%;
}

.BlockBoxLeft div h5 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 2.2rem;
	line-height: 3rem;
	color: #000000;
}

.BlockBoxLeft div p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 1.9rem;
	color: #4a4a4a;
}

.BlockUserBox button {
	background: transparent;
	padding: 0.5rem 1rem;
	border: none;
	outline: none;
	cursor: pointer;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 1.4rem;
	line-height: 1.9rem;
	color: #3a0dbb;
}

.RightBlockedUserContainer {
	/* width: 52rem; */
	flex-basis: 100%;
	height: 100%;
	position: relative;
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	padding-right: 14rem;
}

.RightBlockedUserContainer::-webkit-scrollbar {
	display: none;
}

.LeftArrow {
	position: absolute;
	top: 0;
	right: 0;
	width: 7.5rem;
	height: 7.2rem;
	background: #3a0dbb;
	border-radius: 1.39rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;

	&:hover {
		background: #280b79;
	}
}

.Note {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 2.8rem;
	line-height: 3.9rem;
	color: #000000;
	margin-bottom: 1.7rem;
	margin-top: 11rem;
}

.NoteText {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 1.8rem;
	line-height: 2.5rem;
	color: #403f3f;
	margin-bottom: 5.4rem;
}

.BlockedPagesText {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 2.8rem;
	line-height: 3.9rem;
	color: #000000;
	margin-bottom: 2.8rem;
}

.BlockPageContainer {
	width: 100%;
	height: 60%;
	overflow-y: scroll;
	scrollbar-width: none;
	padding-bottom: 3rem;
}

.BlockPageContainer::-webkit-scrollbar {
	display: none;
}

@media screen and (max-width: 427px) {
	.RightBlockedUserContainer {
		padding-right: 0;
		padding: 0 6rem;
	}

	.Note {
		margin-top: 4rem;
	}

	.BlockPageContainer {
		height: 80%;
	}

	.BlockUserBox {
		width: 98%;
		height: 13rem;
		background: transparent;
		margin-bottom: 1.9rem;
		box-shadow: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 2.7rem 0 1.4rem;
		border-radius: 12px;
		border: 0.5px solid #280b79;
	}
	.BlockBoxLeft img {
		width: 9rem;
		height: 9rem;
		border-radius: 50%;
	}

	.BlockUserBox .blockedStatusButton {
		background: #8498D147;
		color: #280b79;
		padding: 1.5rem 3rem;
		border-radius: 4px;
        width: 12rem;
        text-align: center;
	}
	.BlockUserBox  .blockStatusButton {
		background: #1B47C1;
		color: white;
		padding: 1.5rem 3rem;
		border-radius: 4px;
        width: 12rem;
        text-align: center;
	}
}
